<template>
<div>
  <main>
    <div class="container">
      <div class="row feed-body justify-content-center">
        <div class="col-lg-6 feeds">
          <div v-for="(item, index) in postList" :key="item._id">
             <PostListItem :postItem="item" :index="index" ></PostListItem>
          </div>
          <Loader :stat='loader' theme="skeleton-feed"></Loader>
        </div>
      </div>
    </div>
  </main>
   <PostViewer v-if="postFullView"  :postData="postFullViewData"></PostViewer>
   </div>
</template>
<script>
import axios from '@/plugins/axios'

export default {
  name: "Home",
  components: {

  },
  data () {
        return {
            page:1,
            next:0,
            postList:[],
            loader:true,
            postFullView:false,
            postFullViewData:{}
        }
  },
  created: function () {
     window.scrollTo(0, 0)
  },
  beforeMount() {
    this.getFeedData();
  },
  mounted() {
    this.getNextPage();
  },
  methods: {
    getFeedData: function () {
        var self = this
        if (self.page == 0 ) return false;
        self.loader=true
        axios.get('/api/feed/hash?page='+self.page+'&hash='+this.$route.params.hashtag).then(response => {
          self.page = response.data.data.next;
          response.data.data.list.forEach(item => { self.postList.push(item)});
          localStorage.setItem("homeFeedList", JSON.stringify(self.postList));
          self.loader=false
        }).catch(error => {
          self.loader=false
          self.page =0
        })
    },
    getNextPage() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
         this.getFeedData()
        }
      }
    }
  },
  watch:{
    postFullView:function(){
      if(this.postFullView) document.querySelector('body').classList.add('full-view');
      else document.querySelector('body').classList.remove('full-view');
    }
  },
};
</script>
